// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("C:\\Users\\Yuri\\Desktop\\freedom\\distribuidora de gelo\\distribuidora\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contato-js": () => import("C:\\Users\\Yuri\\Desktop\\freedom\\distribuidora de gelo\\distribuidora\\src\\pages\\contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-index-js": () => import("C:\\Users\\Yuri\\Desktop\\freedom\\distribuidora de gelo\\distribuidora\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sobre-js": () => import("C:\\Users\\Yuri\\Desktop\\freedom\\distribuidora de gelo\\distribuidora\\src\\pages\\sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */)
}

